
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AddLayerProductionProcessModal from '../modals/addLayerProductionProcessModal.vue';
import EditLayerProductionProcessModal from '../modals/editLayerProductionProcessModal.vue';
import store from '@/store';
import utils from '@/utils';
import * as OM from '@/Model';
import { ProductionPhaseConfigurationClient } from '@/services/Services';

@Options({
    components: {
    }
})
export default class editLayerProductionProcess extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() layer: OM.LayerComposition;
    @Prop() productionPhase: string;
    @Prop() allProductionProcesses: OM.ProductionProcessListVM[];
    @Prop() currentProductionProcesses: OM.FabricProductionProcess[];
    @Prop() countries: OM.TextIdentifier[];

    openedCarousel: string[] = [];
    hideEditAction: boolean = false;
    isLeather: boolean = false;

    created() {
        this.hideEditAction = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);
        ProductionPhaseConfigurationClient.checkIfPhaseIsLeather(this.productionPhase)
        .then(x => {
            this.isLeather = x;
        })
    }

    get isWeastagePercentageGSMVisible(){
        return this.currentProductionProcesses.filter( x => x.productionPhase.text == this.productionPhase && x.emissionCalculation == null)
            .some( x => x.productionPhase.showWastage);
    }

    get rawMaterials() {
        var ris = [];
        for (let name in this.grouped) {
            for (let materialId in this.grouped[name]) {
                const materialData = this.grouped[name][materialId][0].rawMaterial;
                const productionCountry = this.grouped[name][materialId][0].productionCountry;
                if (!ris.some(material => material.identifier === materialData.identifier)) {
                    var obj = {
                        identifier: materialData.identifier,
                        rawMaterial: materialData,
                        productionCountry: productionCountry
                    }
                    ris.push(obj);
                }
            }
        }
        return ris;
    }

    get grouped() {
        if(this.currentProductionProcesses.length == 0)
            return [];

        let groupedByName = this.currentProductionProcesses
        .filter( x => !x.rawMaterialIndipendent)
        .filter( x => x.productionPhase.text == this.productionPhase)
        .filter( x => x.emissionCalculation == null)
        .reduce((acc, item) => {
            if (!acc[item.name]) {
                acc[item.name] = [];
            }
            acc[item.name].push(item);
            return acc;
        }, {});

        // Secondo passaggio: raggruppamento per "rawMaterial" all'interno di ogni gruppo "name"
        for (let name in groupedByName) {
            groupedByName[name] = groupedByName[name].reduce((acc, item) => {
                let key = item.rawMaterial.identifier;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {});
        }

        return groupedByName;
    }

    get co2eTotalsByMaterial() {
        let totals = {};
        for (let name in this.grouped) {
            for (let materialId in this.grouped[name]) {
                if (!totals[materialId]) {
                    totals[materialId] = 0;
                }
                totals[materialId] += this.grouped[name][materialId].reduce((sum, item) => sum + item.cO2e, 0);
            }
        }
        return totals;
    }

    get weastagePercentageGSMTotalsByMaterial() {
        var fabrics = this.layer.fabricCompositions;
        
        var result = {};
        fabrics.forEach( fabric => {
            result[fabric.rawMaterial.identifier] = fabric.wastagePercentageGSM;
        })

        return result;
    }

    get filteredAllProductionProcesses(){
        let usati = this.currentProductionProcesses.reduce((final, x) => {
            if(!final[x.name]) //inizializza
                final[x.name] = 0;

                final[x.name]++;
            return final;
        }, {})

        return this.allProductionProcesses.filter(x => !x.isUnitCalculated && (!usati[x.name] || usati[x.name] < this.layer.fabricCompositions.length))
            .map( x => {
                return {
                    identifier: x.identifier,
                    text: x.name
                }
            })
    }

    get canAddProductionProcess(){
        let usati = this.currentProductionProcesses
            .reduce((final, x) => {
                if(!final[x.name]) //inizializza
                    final[x.name] = 0;

                final[x.name]++;
                return final;
            }, {})
        let uniques = this.currentProductionProcesses
            .filter(x => x.isUnique);

        return this.allProductionProcesses
            .filter(x => !x.isUnitCalculated && (!usati[x.name] || usati[x.name] < this.layer.fabricCompositions.length)) // && !uniques.some(c => c.name == x.name))
            .filter(x => !uniques.some(c => c.uniqueKey == x.uniqueKey))
            // .reduce((final, x) => {
            //     if(!final[x.productionGroup])
            //         final[x.productionGroup] = [];

            //     final[x.productionGroup].push({
            //         identifier: x.identifier,
            //         text: x.name,
            //     });
            //     return final;
            // }, {})
            .length > 0;
    }

    addProductionProcess(){
        this.$opModal.show(AddLayerProductionProcessModal, {
            estimationIdentifier: this.estimationIdentifier,
            layer: this.layer,
            allProductionProcesses: this.allProductionProcesses,
            currentProductionProcesses: this.currentProductionProcesses,
            countries: this.countries,
            productionPhase: this.productionPhase,
            saveCallback: (addEstimationLayerProductionProcessVM : OM.AddEstimationLayerProductionProcessVM) => {
                store.state.estimationClient.editLayerProductionProcess(addEstimationLayerProductionProcessVM)
                .then( x => {
                    this.$opModal.closeAll();
                    this.$emit("init");
                })
            }
        })
    }
    
    editProductionProcess(rawMaterialsGroup: any, name: string){
        let unnamed = {};
        for(let p in rawMaterialsGroup){
            unnamed[rawMaterialsGroup[p][0].rawMaterial.identifier] = {
                value: rawMaterialsGroup[p][0].units,
                key: rawMaterialsGroup[p][0].productionCountry.identifier,
            }
        }
        this.$opModal.show(EditLayerProductionProcessModal, {
            estimationIdentifier: this.estimationIdentifier,
            layer: this.layer,
            allProductionProcesses: this.allProductionProcesses,
            currentProductionProcesses: this.currentProductionProcesses,
            countries: this.countries,
            productionPhase: this.productionPhase,
            productionProcessName: name,
            rawMaterialCountryAndUnits: unnamed,
            saveCallback: (editEstimationLayerProductionProcessVM : OM.AddEstimationLayerProductionProcessVM) => {
                store.state.estimationClient.editLayerProductionProcess(editEstimationLayerProductionProcessVM)
                .then( x => {
                    this.$opModal.closeAll();
                    this.$emit("init");
                })
            }
        })
    }

    removeProductionProcess(processName: string){
        store.state.estimationClient.removeLayerProductionProcessFromFabric(this.estimationIdentifier, this.layer.layerName, processName)
        .then(x => {
            this.$emit("init");
        })
    }

    // editLayerProductionProcess(item: OM.FabricProductionProcess) {
    //     var index = this.currentProductionProcesses.indexOf(item);
        
    //     this.$opModal.show(EditLayerProductionProcessModal, {
    //         estimationIdentifier: this.estimationIdentifier,
    //         layer: this.layer,
    //         allProductionProcesses: this.allProductionProcesses,
    //         countries: this.countries,
    //         modelToEdit: item,
    //         saveCallback: (editEstimationLayerProductionProcessVM : OM.EditEstimationLayerProductionProcessVM) => {
    //             editEstimationLayerProductionProcessVM.index = index;
    //             store.state.estimationClient.editLayerProductionProcess(editEstimationLayerProductionProcessVM)
    //             .then( x => {
    //                 this.$opModal.closeAll();
    //                 this.$emit("init");
    //             })
    //         }
    //     })
    // }

    removeLayerProductionProcess(productionProcessName: string){
        var removeEstimationsGarmentLayersVM = new OM.RemoveEstimationLayerProductionProcessVM();
        removeEstimationsGarmentLayersVM.identifier = this.estimationIdentifier;
        removeEstimationsGarmentLayersVM.productionProcessName = productionProcessName;
        // removeEstimationsGarmentLayersVM.index = index;
        removeEstimationsGarmentLayersVM.layerName = this.layer.layerName;

        store.state.estimationClient.removeLayerProductionProcess(removeEstimationsGarmentLayersVM)
        .then( x => {
            this.$emit("init");
        })
    }
}
